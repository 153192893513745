import * as firebase from 'firebase';
import "firebase/performance";
import "firebase/analytics";

var config = {
    apiKey: "AIzaSyDsmaVYzz8dV1X9fPjMQXg452_lfZi0a3Q",
    authDomain: "co-instructed-develop.firebaseapp.com",
    databaseURL: "https://co-instructed-develop.firebaseio.com",
    projectId: "co-instructed-develop",
    storageBucket: "co-instructed-develop.appspot.com",
    messagingSenderId: "988613198395",
    appId: "1:988613198395:web:1d5cbc40f2381225e2d21e",
    measurementId: "G-GN8EG8PJ5K"
};


class Firebase {
    constructor() {
        firebase.initializeApp(config);

        /* Helper */

        this.fieldValue = firebase.firestore.FieldValue;
        this.emailAuthProvider = firebase.auth.EmailAuthProvider;
        this.oAuthProvider = firebase.auth.OAuthProvider;

        /* Firebase APIs */

        this.auth = firebase.auth();
        this.db = firebase.firestore();
        this.analytics = firebase.analytics();
        this.performance = firebase.performance();
        // this.db.settings({ timestampsInSnapshots: true });
        this.storage = firebase.storage()
        this.storageTaskEvent = firebase.storage.TaskEvent
        this.storageTaskState = firebase.storage.TaskState

        /* Social Sign In Method Provider */

        this.googleProvider = new firebase.auth.GoogleAuthProvider();
        this.facebookProvider = new firebase.auth.FacebookAuthProvider();
        this.twitterProvider = new firebase.auth.TwitterAuthProvider();

        // Initialize Performance Monitoring and get a reference to the service
        const perf = firebase.performance();

        var drawerOpen = false
        var drawerListener = ({})

        this.azureTenant = "common"
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);

    doSignInWithTwitter = () =>
        this.auth.signInWithPopup(this.twitterProvider);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
        });

    doReauthenticate = (password) => {
        var user = this.auth.currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(user.email, password);
        return user.reauthenticateWithCredential(cred);
    }

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.analytics.setUserId(authUser.uid)
                this.user(authUser.uid)
                    .get()
                    .then(snapshot => {
                        const dbUser = snapshot.data() ? snapshot.data() : null

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        };
                        next(authUser);
                    });
            } else {
                fallback();
            }
        });

    
    toogleDrawer(){
        this.drawerOpen = !this.drawerOpen
        this.drawerListener(this.drawerOpen)
    }

    setDrawerListener(listener){
        this.drawerListener = listener
    }

    logEvent(eventName, parameters){
        this.analytics.logEvent(eventName, parameters)
        console.log("logEvent = ", eventName, parameters);
    }
    

    // *** User API ***

    user = userId => this.db.doc(`users/${userId}`)
    userPublic = otherUserId => this.db.doc(`users/${otherUserId}/public/${otherUserId}`)
    users = () => this.db.collection('users')

    userCertificateDone = () => this.db.collectionGroup(`progress`)
        .where("0raO3dz4TPrWrFPP40au.exerciseDone", "==", true)
        .where("9LYkyxJk38GV14pTTCk1.exerciseDone", "==", true)
        .where("ESV91wTiRfJH6xgGqSmI.exerciseDone", "==", true)
        .where("J6rUCBBgyh8qnm1MIPXM.exerciseDone", "==", true)
        .where("QBYzyXjVDbo6jInmkfop.exerciseDone", "==", true)
        .where("gYIXdH3CVxBgzyMDPTTQ.exerciseDone", "==", true)


    course = courseId => this.db.doc(`courses/${courseId}`)
    courseByUrlName = urlName => this.db.collection(`courses`).where("urlName", "==", urlName).limit(1)
    courses = (videoGroup) => this.db.collection(`courses`).where("active", "==", true).where("startDate", "<", new Date()).where("videoGroup", "array-contains", videoGroup).orderBy("startDate").orderBy("sort")
    coursesAdmin = () => this.db.collection(`courses`).orderBy("startDate").orderBy("sort")

    courseVideos = courseId => this.db.collection(`courses/${courseId}/videos`).orderBy("sort")
    courseExercises = courseId => this.db.collection(`courses/${courseId}/exercises`)
    coursePagePre = courseId => this.db.doc(`courses/${courseId}/pages/pre`)

    userProgress = userId => this.db.doc(`users/${userId}/progress/${userId}`)

    progressSubscription = userId => this.db.collection(`progress/${userId}/subscriptions`)
    progressSubscriptionAdmin = (userId, courseId) => this.db.collection(`progress/${userId}/subscriptions`).where("courseId", "==", courseId).orderBy("createdAt")

    progressVideo = userId => this.db.collection(`progress/${userId}/videos`)
    progressVideoPlatform = userId => this.db.collection(`progress/${userId}/videoPlatform`)
    progressExercise = (userId) => this.db.collection(`progress/${userId}/exercises/`)


    groupByUserId = (userId) => this.db.collection(`groups`).where("userIds", "array-contains", userId).limit(1)
    groupCourseExerciseProgress = (groupId, courseId) => this.db.doc(`groups/${groupId}/courses/${courseId}`)

    certificates = () => this.db.collection('certificate')


    usersDeleted = userId => this.db.doc(`usersDeleted/${userId}`)
    company = () => this.db.collection('companies').limit(1)
    // companyByMailEnding = mailEnding => this.db.collection('companies').where("domainEndings", "array-contains", mailEnding).limit(1)
    companyByMailEnding = mailEnding => this.db.collection('companies').limit(1)

    azureToken = userId => this.db.doc(`tokens/${userId}/azure/access`)

    // Admin

    admin = userId => this.db.doc(`admin/permissions/users/${userId}`)
    groups = () => this.db.collection('groups')
    billingResults = (yearMonth) => this.db.doc(`admin/billing/result/${yearMonth}`)
    calculateBilling = (yearMonth) => this.db.doc(`admin/billing/calculateBilling/${yearMonth}`)
    validation = () => this.db.collection('validation')


    // helper

    userObject = userId => 
        this.user(userId)
        .get()
        .then(snapshot => {
            return this.mapItem(snapshot)
        })

    userCertificateDoneObject = () => 
        this.userCertificateDone()
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })

    userPublicObject = otherUserId => 
        this.userPublic(otherUserId)
        .onSnapshot(snapshot => {
            return this.mapItem(snapshot)
        })

    userList = () => 
        this.users()
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })

    createUser = (userId, email, name, surname) => {
        // if(azureId){
        //     return this.user(userId).set(
        //         {
        //           name,
        //           surname,
        //           email,
        //           division,
        //           azureId,
        //           videoGroup: "all",
        //           createdAt: this.fieldValue.serverTimestamp(),
        //           lastLoginAt: this.fieldValue.serverTimestamp(),
        //         },
        //         { merge: true },
        //     )
        // }else{
            return this.user(userId).set(
                {
                  name,
                  surname,
                  email,
                  videoGroup: "all",
                  createdAt: this.fieldValue.serverTimestamp(),
                  lastLoginAt: this.fieldValue.serverTimestamp(),
                },
                { merge: true },
            )
        // }
        
      }

    updateUser = (userId, name, surname, azureId) => 
        this.user(userId).set(
        {
          name,
          surname,
          azureId,
          lastLoginAt: this.fieldValue.serverTimestamp(),
        },
        { merge: true },
      )

    setAzureToken = (userId, token) =>  
        this.azureToken(userId).set(
        {
            token,
            createdAt: this.fieldValue.serverTimestamp(),
        },
        { merge: true },
    )
    
    courseObject = courseId => 
        this.course(courseId)
        .get()
        .then(snapshot => {
            return this.mapItem(snapshot)
        })

    courseObjectByUrlNameOrId = urlNameOrId => 
        this.courseByUrlName(urlNameOrId)
        .get()
        .then(snapshots => {
            const items = this.mapList(snapshots)
            if(items && items[0]){
                return items[0]
            }
            return this.courseObject(urlNameOrId)
        })

    coursesList = (videoGroup) => 
        this.courses(videoGroup ? videoGroup : "all")
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })

    coursesAdminList = () => 
        this.coursesAdmin()
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })

    courseVideosList = courseId => 
        this.courseVideos(courseId)
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })

    courseExercisesList = courseId => 
        this.courseExercises(courseId)
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })

    coursePagePreItem = courseId => 
        this.coursePagePre(courseId)
        .get()
        .then(snapshot => {
            return this.mapItem(snapshot)
        })

    userProgressObject = userId => 
        this.userProgress(userId)
        .get()
        .then(snapshot => {
            return this.mapItem(snapshot)
        })

    progressSubscriptionListByCourse = (userId, courseId) => 
        this.progressSubscriptionAdmin(userId, courseId)
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })


    progressSubscriptionStart = (userId, courseId) => 
        this.progressSubscription(userId)
        .add({
            type: "start",
            courseId: courseId,
            createdAt: this.fieldValue.serverTimestamp()
        })
    progressSubscriptionEnd = (userId, courseId) => 
        this.progressSubscription(userId)
        .add({
            type: "end",
            courseId: courseId,
            createdAt: this.fieldValue.serverTimestamp()
        })
    progressSubscriptionRestart = (userId, courseId) => 
        this.progressSubscription(userId)
        .add({
            type: "restart",
            courseId: courseId,
            createdAt: this.fieldValue.serverTimestamp()
        })

    progressVideoPlatformSelected = (userId, courseId, platform) => 
        this.progressVideoPlatform(userId)
        .add({
            platform: platform,
            courseId: courseId,
            createdAt: this.fieldValue.serverTimestamp()
        })

    progressExerciseUpdate = (userId, courseId, verifierId, done) => 
        this.progressExercise(userId)
        .add({
            verifiedBy: verifierId,
            courseId: courseId,
            done: done,
            createdAt: this.fieldValue.serverTimestamp()
        })

    usersDeletedAdd = (user) => 
        this.usersDeleted(user.id)
        .set({
            email: user.email,
            createdAt: this.fieldValue.serverTimestamp()
        })

    adminObject = userId => 
        this.admin(userId)
        .get()
        .then(snapshot => {
            return this.mapItem(snapshot)
        })

    setProgressVideoDone = (userId, courseId, videoId) => 
        this.progressVideo(userId)
        .add({
            courseId: courseId,
            videoId: videoId,
            createdAt: this.fieldValue.serverTimestamp()
        })


    billingResultsObject = yearMonth => 
        this.billingResults(yearMonth)
        .get()
        .then(snapshot => {
            return this.mapItem(snapshot)
        })

    updateCalculateBilling = (yearMonth) => 
        this.calculateBilling(yearMonth)
        .set({
            updatedAt: this.fieldValue.serverTimestamp()
        })

    validationList = () => 
        this.validation()
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })

    addError = (type, data) => 
        this.error()
        .add({
            type,
            data: JSON.stringify(data),
            createdAt: this.fieldValue.serverTimestamp()
        })

    certificateList = () => 
        this.certificates()
        .get()
        .then(snapshots => {
            return this.mapList(snapshots)
        })


    // Mapping

    mapItem = (snapshot) => {
        const item = snapshot.exists ? snapshot.data() : null
        if (item) {
            item.id = snapshot.id
        }
        return item
    }
    mapList = (snapshots) => {
        var items = []
        snapshots.forEach(function (doc) {
            var item = doc.data()
            item.id = doc.id
            items.push(item)
        })
        return items
    }


    // old


    error = () => this.db.collection(`error`)
    reminder = () => this.db.collection(`reminder`)

    // *** Message API ***

    message = uid => this.db.doc(`messages/${uid}`)

    messages = () => this.db.collection('messages')

}

export default Firebase;